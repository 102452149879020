<div class="help-popover">
  <div
    mat-dialog-content
    style="
      font-size: 14px;
      color: #000000;
      font-family: Roboto, Helvetica Neue, sans-serif;
    "
  >
    <p>Чтобы просмотреть контроль успеваемости:</p>
    <ol>
      <li>Введите номер группы и нажмите на кнопку [Найти].</li>
      <li>Нажмите на [Предметы] для получения общей информации о предмете.</li>
      <li>
        Нажмите на [Статистика] для получения статистики по определенному
        предмету.
      </li>
      <li>Выберите нужный предмет.</li>
      <li>
        Будет отображена статистика по выбранному предмету. Нажмите на [Печать]
        для печати или на [Excel] для экспорта в MS Excel
      </li>
    </ol>
  </div>
  <div mat-dialog-actions align="right">
    <button
      mat-raised-button
      color="primary"
      class="mat-icon-button-close"
      mat-dialog-close
    >
      Понятно
    </button>
  </div>
</div>
